import cn from "classnames";

import styles from "./index.module.scss";

export interface FeatureProps {
  order: number;
  icon: JSX.Element;
  iconLayerPosition: "front" | "back";
  title: string;
  subtitle: string;
  body: JSX.Element;
  testimonials: string[];
}

const Feature = ({
  order,
  icon,
  iconLayerPosition,
  title,
  subtitle,
  body,
  testimonials,
}: FeatureProps) => (
  <div className={cn(styles.feature, "border bg-body-secondary rounded-3")}>
    <div className={styles.iconWrapper}>
      <span
        className={cn(styles.icon, {
          [styles.iconBack]: iconLayerPosition === "back",
        })}
      >
        {icon}
      </span>
    </div>
    <div className={styles.header}>
      <span className={styles.order}>{order}</span>
      <div className={styles.titles}>
        <h4 className={styles.title}>{title}</h4>
        <small className={styles.subtitle}>{subtitle}</small>
      </div>
    </div>
    <div className={styles.body}>
      <div>{body}</div>
      <div className={styles.testimonialGroup}>
        {testimonials.map((testimonial, index) => (
          <p
            key={`feature-${title}-testimonial-${index}`}
            className={styles.testimonial}
          >
            <span className="highlighter highlighter-green">
              &ldquo;{testimonial}&rdquo;
            </span>
          </p>
        ))}
      </div>
    </div>
  </div>
);

export default Feature;
