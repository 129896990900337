import cn from "classnames";
import Link from "next/link";

import Image from "@/components/Image";
import Button from "@/components/Button";
import ButtonGroup from "@/components/ButtonGroup";
import H from "@/components/text/Heading";
import Wrapper from "@/components/Wrapper";
import { useDarkMode } from "@/lib/hooks/darkMode";
import { getNavigationLevels } from "@/lib/helpers";

import styles from "./GetStartedSection.module.scss";

const GetStartedSection = (): JSX.Element => {
  const { isDarkMode } = useDarkMode();
  const iconVariant = isDarkMode ? "dark" : "light";

  return (
    <section className="d-flex align-items-center flex-column pb-5 text-center">
      <Wrapper
        className={cn(
          styles.wrapper,
          "w-100 d-flex flex-column align-items-center rounded p-5",
        )}
      >
        <header>
          <Image
            className="mb-2"
            src={`/images/illustrations/get-started-${iconVariant}.svg`}
            width={181}
            height={134}
            alt="An illustration of students holding their exam results"
          />
          <H className="text-uppercase text-body-secondary" tag="h3" size="h6">
            What are you studying?
          </H>
          <H tag="h2" size="h1">
            Get started - for free
          </H>
        </header>
        <ButtonGroup gutter="S" className={cn(styles.buttonGroupWrapper)}>
          {getNavigationLevels().map((level, index) => (
            <Button
              href={level.url}
              tag={Link}
              key={index}
              variant="light"
              size="L"
              wide
            >
              {level.title}
            </Button>
          ))}
        </ButtonGroup>
      </Wrapper>
    </section>
  );
};

export default GetStartedSection;
