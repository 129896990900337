import cn from "classnames";

import styles from "./Text.module.scss";

export interface TitleProps {
  tag: "h1" | "h2";
  children: JSX.Element | string;
  classNames?: string;
}

export const Title = ({
  tag: Tag,
  children,
  classNames = "",
}: TitleProps): JSX.Element => (
  <Tag className={cn(styles.title, classNames)}>{children}</Tag>
);

export interface SubtitleProps {
  children: JSX.Element;
  classNames?: string;
}

export const Subtitle = ({
  children,
  classNames = "",
}: SubtitleProps): JSX.Element => (
  <p className={cn(styles.subtitle, classNames)}>{children}</p>
);
