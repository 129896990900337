export interface FeatureProps {
  icon: {
    filename: string;
    alt: string;
  };
  title: string;
  subtitle: string;
  body: JSX.Element;
  testimonials: string[];
  iconLayerPosition: "front" | "back";
}

const FEATURES: FeatureProps[] = [
  {
    icon: {
      filename: "revise",
      alt: "An illustration of a student wearing headphones and holding a book",
    },
    title: "Revise",
    subtitle: "only what you need to",
    body: (
      <p>
        Enjoy the <span className="fw-bold">relief and reassurance</span> that
        every revision guide is written specifically for that syllabus so you
        only revise what you need to know.
      </p>
    ),
    testimonials: [
      "Never felt so relieved in my life",
      "Quite literally has saved my exams on multiple occasions",
    ],
    iconLayerPosition: "front",
  },
  {
    icon: {
      filename: "test-yourself",
      alt: "An illustration of a student completing a test on a laptop",
    },
    title: "Test yourself",
    subtitle: "and check progress",
    body: (
      <p>
        Feel <span className="fw-bold">empowered and confident</span> going into
        exams knowing that you’ve covered all the topics and have a greater
        understanding of each subject.
      </p>
    ),
    testimonials: [
      "The tailored level of questions builds so much confidence within my students",
    ],
    iconLayerPosition: "front",
  },
  {
    icon: {
      filename: "improve",
      alt: "An illustration of a student holding a stack of exam papers",
    },
    title: "Improve",
    subtitle: "answer by answer",
    body: (
      <p>
        Gain <span className="fw-bold">certainty</span> that you’re answering
        questions that get <span className="fw-bold">maximum marks</span>, from
        model answers for every question, explained by an expert examiner or
        teacher.
      </p>
    ),
    testimonials: [
      "I went from a 6-7-7 in Year 10 to 9-9-9 for my real exams, only because of your superb resources",
    ],
    iconLayerPosition: "back",
  },
];

export default FEATURES;
