import cn from "classnames";

import Image from "@/components/Image";
import FEATURED from "@/config/data/featured";

import styles from "./index.module.scss";

const Featured = () => {
  return (
    <section className={cn(styles.featured, "py-5 text-center")}>
      <div className="container">
        <header>
          <h2>Save My Exams is featured in</h2>
        </header>
        <ul className="row my-5 list-unstyled justify-content-center">
          {FEATURED.map((outlet, index) => (
            <li
              key={index}
              className="col-md-6 col-lg-3 py-md-0 py-3 px-0 mx-0"
            >
              <Image
                src={outlet.logo}
                width={200}
                height={150}
                alt={outlet.name}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Featured;
