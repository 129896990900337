import cn from "classnames";
import Link from "next/link";

import JoinModal from "@/components/Navbar/components/JoinModal";
import Trustpilot from "@/components/Trustpilot/basic";
import { EVENT_VALUES } from "@/config/data/tracking";
import Button from "src/components/Button";
import Wrapper from "src/components/Wrapper";
import { Subtitle, Title } from "../Text";

import styles from "./BaseHeroSection.module.scss";

export interface BaseHeroSectionProps {
  title: {
    tag: "h1" | "h2";
    children: JSX.Element;
  };
  subtitle: JSX.Element;
  supplementaryText: JSX.Element;
  cta: {
    text: string;
    href?: string;
    onClick: () => void;
  };
}

const BaseHeroSection = ({
  title,
  subtitle,
  supplementaryText,
  cta,
}: BaseHeroSectionProps): JSX.Element => (
  <div
    className={cn(
      styles.baseHeroSection,
      "pt-5 d-flex align-items-center flex-column gap-4 px-2",
    )}
  >
    <Wrapper className="container-lg col-lg-8 d-flex flex-column align-items-center gap-4">
      <div className="mx-auto text-center">
        <Trustpilot />
      </div>
      <Title tag={title.tag} classNames="col-md-11 col-xl-12 text-center">
        {title.children}
      </Title>
      <Subtitle classNames="col-md-10 col-xl-12 text-center">
        {subtitle}
      </Subtitle>
      <div className="d-flex justify-content-center mb-4">
        {!cta.href && (
          <JoinModal
            text={cta.text}
            onClick={cta.onClick}
            className="py-2 px-5 align-self-start"
            trigger={
              EVENT_VALUES.shownRegistrationFormTrigger.homePageJoinNowCta
            }
          />
        )}

        {cta.href && (
          <Button
            href={cta.href}
            tag={Link}
            variant="primary"
            size="L"
            className="py-2 px-5 align-self-start"
            onClick={cta.onClick}
          >
            {cta.text}
          </Button>
        )}
      </div>
    </Wrapper>
    <Wrapper className="container-lg">
      <p className="text-center w-100">{supplementaryText}</p>
    </Wrapper>
  </div>
);

export default BaseHeroSection;
