import cn from "classnames";

import { P } from "src/components/text";
import ExpertCard from "src/components/ExpertCard";

import EXPERTS from "src/config/data/experts";

import styles from "./index.module.scss";

const Experts = () => {
  return (
    <section className={cn(styles.wrapper, "container-lg")}>
      <header className="col-lg-8">
        <h2>Meet our experts</h2>
        <P>
          Our revision resources are written by teachers and examiners. That
          means notes, questions by topic and worked solutions that show exactly
          what the examiners for each specific exam are looking for.
        </P>
        <P>We work harder so you can study smarter.</P>
      </header>
      <div className="row justify-content-center g-4 mt-2 col-sm-6 col-md-12 mx-0 px-0">
        {EXPERTS.map((expert) => (
          <ExpertCard checklist expert={expert} key={expert.path} link={true} />
        ))}
      </div>
    </section>
  );
};

export default Experts;
