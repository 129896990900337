const FEATURED = [
  {
    name: "Forbes",
    logo: "/images/news/forbes-logo.svg",
  },
  {
    name: "Daily Express",
    logo: "/images/news/daily-express-logo.svg",
  },
  {
    name: "Metro",
    logo: "/images/news/metro-logo.svg",
  },
  {
    name: "The Guardian",
    logo: "/images/news/the-guardian-logo.svg",
  },
];

export default FEATURED;
