/**
 * Adds consistant spacing around a group of buttons using the same concept as the Bootstrap grid gutters - negative
 * margin top/left/right on the wrapper, and margin top/left/right on the children.
 */

import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const GUTTER_CLASSES = {
  S: styles.gutterSmall,
  M: styles.gutterMedium,
  L: styles.gutterLarge,
};

const ButtonGroup = ({
  children,
  className = null,
  gutter = "M",
  tag = "div",
  ...rest
}) => {
  const Element = tag;
  return (
    <Element
      className={cn(styles.group, GUTTER_CLASSES[gutter], className)}
      {...rest}
    >
      {children}
    </Element>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gutter: PropTypes.oneOf(["S", "M", "L"]),
  tag: PropTypes.string,
};
