import cn from "classnames";

import Image from "@/components/Image";
import Wrapper from "@/components/Wrapper";
import Feature from "@/components/Feature";
import { Title } from "../Text";

import { FeatureProps } from "@/config/data/features";
import { useDarkMode } from "@/lib/hooks/darkMode";

import styles from "./FeaturesSection.module.scss";

export interface FeaturesSectionProps {
  title: JSX.Element | string;
  features: FeatureProps[];
}

const FeaturesSection = ({
  title,
  features,
}: FeaturesSectionProps): JSX.Element => {
  const { isDarkMode } = useDarkMode();
  const iconVariant = isDarkMode ? "dark" : "light";

  return (
    <Wrapper
      className={cn(
        styles.featuresSection,
        "py-5 text-center d-flex align-items-center flex-column",
      )}
    >
      <Title tag="h2" classNames="col-lg-8 pb-5">
        {title}
      </Title>

      <div className={cn(styles.featuresGroup, "w-100 pt-5")}>
        {features.map((feature, index) => (
          <Feature
            key={`feature-${feature.title}`}
            order={index + 1}
            icon={
              <Image
                src={`/images/illustrations/${feature.icon.filename}-${iconVariant}.svg`}
                width={130}
                height={130}
                alt={feature.icon.alt}
              />
            }
            title={feature.title}
            subtitle={feature.subtitle}
            body={feature.body}
            testimonials={feature.testimonials}
            iconLayerPosition={feature.iconLayerPosition}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default FeaturesSection;
