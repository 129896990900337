import { ADDRESS, TEAM_EMAIL } from "src/config/data/constants";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { baseUrl, attachmentDomain },
} = getConfig();

const JSONLD = () => {
  const educationalOrganizationData = {
    "@context": "http://schema.org",
    "@type": "EducationalOrganization",
    "@id": `${baseUrl}/#educationalOrganization`,
    name: "Save My Exams",
    legalName: "Save My Exams Ltd.",
    description:
      "Save My Exams is an online education platform dedicated to helping GCSE, IGCSE, A-Level, and IB students achieve better grades.",
    url: baseUrl,
    logo: `${attachmentDomain}/logo/sme-logo-small.svg`,
    email: TEAM_EMAIL,
    address: {
      "@type": "PostalAddress",
      streetAddress: ADDRESS.STREET_ADDRESS,
      addressLocality: ADDRESS.LOCALITY,
      addressRegion: ADDRESS.REGION,
      addressCountry: ADDRESS.COUNTRY,
      postalCode: ADDRESS.POSTAL_CODE,
    },
    sameAs: [
      "https://www.facebook.com/SaveMyExamsUK/",
      "https://twitter.com/SaveMyExams",
      "https://www.linkedin.com/company/save-my-exams/",
      "https://uk.trustpilot.com/review/www.savemyexams.co.uk",
    ],
    founder: {
      "@type": "Person",
      alternateName: "James Undrell",
      alumniOf: {
        "@type": "CollegeOrUniversity",
        name: "University of Oxford",
        address: {
          type: "PostalAddress",
          addressLocality: "Oxford, United Kingdom",
          addressRegion: "Oxfordshire",
          postalCode: "OX1 2JD",
          streetAddress: "Wellington Square",
        },
        sameAs: [
          "https://www.ox.ac.uk/",
          "https://en.wikipedia.org/wiki/University_of_Oxford",
          "https://www.wikidata.org/wiki/Q34433",
        ],
      },
      image: `${attachmentDomain}/uploads/2024/01/jamie-undrell.jpg`,
      jobTitle: "CEO and Founder",
      knowsAbout: [
        "Education",
        "Revision",
        "Teaching",
        "Exams",
        "Science",
        "Chemistry",
        "IB",
        "GCSE",
        "IGCSE",
        "A Level",
        "Business",
        "Startups",
        "Studying",
        "Finance",
      ],
      name: "Jamie Undrell",
      sameAs: [
        "https://www.linkedin.com/in/jamie-undrell/",
        "https://www.crunchbase.com/person/jamie-undrell",
        "https://theorg.com/org/save-my-exams/org-chart/jamie-undrell",
      ],
    },
  };

  return (
    <>
      <script
        id="json-ld-corporation"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(educationalOrganizationData),
        }}
      />
    </>
  );
};

export default JSONLD;
