import cn from "classnames";

import checkSession from "src/lib/server/checkSession";
import getUser from "src/lib/server/getUser";

import FEATURES from "src/config/data/features";
import ROUTES from "src/config/data/routes";
import { EVENT_TYPES, EVENT_VALUES } from "src/config/data/tracking";
import * as tracking from "src/lib/tracking";

import BaseHeroSection from "@/components/pages/index/BaseHeroSection";
import Reviews from "src/components/Reviews";
import Wrapper from "src/components/Wrapper";
import Experts from "src/components/pages/index/Experts";
import Featured from "src/components/pages/index/Featured";
import FeaturesSection from "src/components/pages/index/FeaturesSection";
import GetStartedSection from "src/components/pages/index/GetStartedSection";
import JSONLD from "src/components/scripts/JSONLD";
import { Highlight } from "src/components/text";
import {
  PRACTICE_QUESTION_COUNT,
  REVISION_NOTE_COUNT,
} from "src/config/data/constants";

import styles from "src/styles/pages/home.module.scss";

const HeroSection = () => {
  const trackCtaClick = () => {
    tracking.event(EVENT_TYPES.interactedWithHomePage, {
      interaction: EVENT_VALUES.engageHomePageInteraction.heroSectionJoinNow,
    });
  };

  return (
    <BaseHeroSection
      title={{
        tag: "h1",
        children: (
          <>
            The examiner-written revision resources that improve your{" "}
            <Highlight variant="long">grades 2x</Highlight>
          </>
        ),
      }}
      subtitle={
        <>
          Save My Exams is the stress-free path to{" "}
          <span className="highlighter highlighter-yellow">
            helping students study effectively and get higher grades
          </span>{" "}
          than they ever thought possible.
        </>
      }
      supplementaryText={
        <>
          {REVISION_NOTE_COUNT}+ revision notes <span className="mx-1">•</span>{" "}
          Past papers and mark schemes <span className="mx-1">•</span>{" "}
          {PRACTICE_QUESTION_COUNT}+ practice questions and model answers{" "}
          <span className="mx-1">•</span> And lots more
        </>
      }
      cta={{
        text: "Join now",
        onClick: trackCtaClick,
      }}
    />
  );
};

export default function HomePage() {
  return (
    <>
      <JSONLD />
      {/* z-index hack so we could get an icon to sit behind a feature card and also have a white background instead of transparent */}
      <div className={styles.background} />

      <HeroSection />

      <Wrapper className="container-lg">
        <FeaturesSection title="Why it works" features={FEATURES} />
        <GetStartedSection />
      </Wrapper>
      <Wrapper className={cn(styles.greyBackgroundColour, "px-2")}>
        <Experts />
      </Wrapper>
      <Wrapper className="bg-body-secondary py-5">
        <Reviews />
      </Wrapper>
      <div className="container-lg">
        <Featured />
      </div>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const supertokensPageProps = await checkSession({ req, res });
  if (supertokensPageProps) return { props: supertokensPageProps };

  const { user } = await getUser({ req });

  if (user) {
    return {
      redirect: {
        destination: ROUTES.PAGES.MEMBERS,
        permanent: false,
      },
    };
  }

  return {
    props: {
      title: "Revision Notes, Past Papers & Topic Questions",
      user,
    },
  };
}
